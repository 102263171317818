import React from "react"
import theme from "theme"
import { Theme, Text, Section, Icon, Box, Link } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml } from "@quarkly/components"
import * as Components from "components"
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>Wildy</title>
        <meta
          name={"description"}
          content={"Gdzie zaczyna się przygoda na czterech kołach"}
        />
        <meta property={"og:title"} content={"Wildy"} />
        <meta
          property={"og:description"}
          content={"Gdzie zaczyna się przygoda na czterech kołach"}
        />
        <meta
          property={"og:image"}
          content={"https://zixelux.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://zixelux.com/img/Z-Logo-app-icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        text-align="center"
        color="--dark"
        padding="80px 0 0px 0"
        sm-padding="40px 0"
      >
        <Text
          as="h1"
          font="--headline1"
          md-font="--headline2"
          margin="10px 0 0 0"
        >
          Skontaktuj się z nami
        </Text>
        <Text as="p" font="--lead" margin="40px 0 20px 0">
          Gotowy na rozpoczęcie przygody? Oto jak możesz się z nami
          skontaktować:
        </Text>
      </Section>
      <Section
        color="--dark"
        padding="30px 0 100px 0"
        sm-padding="40px 0"
        position="relative"
        quarkly-title="Form-1"
      >
        <Box margin="0px -16px 0px -16px" display="block" flex-wrap="wrap">
          <Box
            display="flex"
            align-self="center"
            flex-wrap="no-wrap"
            justify-content="space-between"
            align-items="flex-start"
            align-content="flex-end"
            flex-direction="row"
            sm-display="block"
          >
            <Box
              sm-padding="64px 0 0 0"
              margin="0 0 0 0"
              max-width="360px"
              position="relative"
              padding="0 0 0 64px"
            >
              <Icon
                size="48px"
                top="0"
                left="0"
                category="md"
                icon={MdLocationOn}
                position="absolute"
              />
              <Text as="h4" margin="6px 0" font="--base">
                Lokalizacja
              </Text>
              <Text as="p" margin="6px 0" font="--headline3">
                Okrężna 2, 78-600 Wałcz, Poland
              </Text>
            </Box>
            <Box
              padding="0 0 0 64px"
              margin="0 0 0 0"
              max-width="360px"
              position="relative"
            >
              <Icon
                left="0"
                category="md"
                icon={MdPhone}
                position="absolute"
                size="48px"
                top="0"
              />
              <Text font="--base" as="h4" margin="6px 0">
                Numer telefonu
              </Text>
              <Text as="p" margin="6px 0" font="--headline3">
                +48 673 873 246
              </Text>
            </Box>
            <Box
              position="relative"
              padding="0 0 0 64px"
              sm-padding="64px 0 0 0"
              margin="0 0 0 0"
              max-width="360px"
            >
              <Icon
                top="0"
                left="0"
                category="md"
                icon={MdEmail}
                position="absolute"
                size="48px"
              />
              <Text font="--base" as="h4" margin="6px 0">
                Adres e-mail
              </Text>
              <Text as="p" margin="6px 0" font="--headline3">
                <Link
                  href="mailto:admin@zixelux.com"
                  text-decoration="none"
                  hover-text-decoration="underline"
                  color="--dark"
                >
                  admin@zixelux.com
                </Link>
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
